@import url("https://fonts.googleapis.com/css2?family=DM+Sans&display=swap");

img {
  max-width: 100%;
  max-height: 100%;
}

body {
  color: #1a1a1a;
  overflow: hidden;
}
html {
  scroll-behavior: smooth;
}

.spacer25 {
  height: 25px;
}
.spacer30 {
  height: 30px;
}

.spacer60 {
  height: 60px;
}

.roboto {
  font-family: "Roboto", sans-serif;
}

.robotoMono {
  font-family: "Roboto Mono", sans-serif;
}

.gabriela {
  font-family: "GabrielaStencil";
}

.teste {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3.5rem;
  aspect-ratio: 1;
  border-radius: 50%;
  outline: none;
  border: none;
  box-shadow: 0 0 8px transparent;
  background-color: #f3f3f3;
  cursor: pointer;
  -webkit-appearance: none;
  transition: background-color 0.4s, box-shadow 0.4s;
  margin-top: 20px;
  margin-bottom: 20px;
}

.controlsMain {
  display: flex;
  padding-top: 60px;
  justify-content: center;
  width: 15%;
  height: 100vh;
}
::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}
.scrollElements {
  border-radius: 80px;
  background-color: #f0f0f0;
  height: 750px;
  width: 100%;
  overflow-y: scroll;
  /* display: flex;
  flex-direction: column;
  align-items: center */
  margin-bottom: 20px;
}

.circle {
  margin-top: 35px;
  margin-right: auto;
  margin-left: auto;
}
.controls {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 2rem;
  margin-right: 2rem;
}

.controls button {
  font-family: "DM Sans", sans-serif;
  background-color: #cab9ac;
  color: #4b4b4b;
  accent-color: #969696;
}
.controls button:hover {
  font-family: "DM Sans", sans-serif;
  background-color: #66a487;
  color: #4b4b4b;
  accent-color: #969696;
}

.btnAnimation {
  margin-bottom: 1rem !important;
  width: 100%;
}

.modelDiv {
  width: 85%;
  display: flex;
  height: 90%;
}

.bottomDiv {
  position: absolute;
  bottom: 0;
  right: 0;
  height: 10%;
  width: 85%;
  font-family: "Roboto Mono", sans-serif;
  font-weight: 700;
}

.rightBlockDiv {
  position: absolute;
  right: 0;
  bottom: 10%;
  height: 50%;
  width: 20%;
  background: transparent;
}

.popUpDiv {
  width: 1150px;
  height: 850px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.critecLogo {
  position: absolute;
  top: 5%;
  left: 2%;
}
.closeBtnDiv {
  position: absolute;
  top: 5%;
  right: 5%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
}
.input {
  background: transparent;
  border-radius: 30px;
  width: 800px;
  height: 60px;
  padding-left: 50px;
  padding-right: 50px;
  font-size: 22px;
  font-weight: 700;
  color: #1a1a1a;
  border: 1px solid #1a1a1a;
}

input[type="radio"] {
  width: 40px;
  margin-right: 20px;
  height: 40px;
  vertical-align: middle;
  -webkit-appearance: none;
  border-radius: 50%;
  outline: none;
  border: 1px solid #000;
}
input[type="radio"]:before {
  content: "";
  display: block;
  width: 60%;
  height: 60%;
  margin: 20% auto;
  border-radius: 50%;
}
input[type="radio"]:checked:before {
  background: #000;
}
.popUpContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

::placeholder {
  color: #1a1a1a;
}
@media only screen and (max-width: 768px) {
  .btnBottomDiv {
    padding: 10px;
    display: block;
    text-align: center;
  }

  .controlsMain {
    display: none;
  }

  .modelDiv {
    width: 100%;
    height: 85%;
  }

  .btnAnimation {
    width: 200px;
    margin-left: 1rem !important;
    margin-right: 1rem !important;
    margin-bottom: 1rem !important;
  }
}
/* HOME */

.homepageDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-color: #1a1a1a;
}

.homepageDiv h2 {
  color: hsla(0, 0%, 0%, 0.9);
  font: normal 140px Varela Round, sans-serif;
  height: 140px;
  left: 0;
  letter-spacing: 5px;
  margin: -80px 0 0 0;
  position: absolute;
  text-align: center;
  text-transform: uppercase;
  top: 50%;
  width: 100%;
  animation: move linear 2000ms infinite;
}

@keyframes move {
  0% {
    text-shadow: 4px -4px 0 hsla(0, 100%, 50%, 1),
      3px -3px 0 hsla(0, 100%, 50%, 1), 2px -2px 0 hsla(0, 100%, 50%, 1),
      1px -1px 0 hsla(0, 100%, 50%, 1), -4px 4px 0 hsla(180, 100%, 50%, 1),
      -3px 3px 0 hsla(180, 100%, 50%, 1), -2px 2px 0 hsla(180, 100%, 50%, 1),
      -1px 1px 0 hsla(180, 100%, 50%, 1);
  }
  25% {
    text-shadow: -4px -4px 0 hsla(180, 100%, 50%, 1),
      -3px -3px 0 hsla(180, 100%, 50%, 1), -2px -2px 0 hsla(180, 100%, 50%, 1),
      -1px -1px 0 hsla(180, 100%, 50%, 1), 4px 4px 0 hsla(0, 100%, 50%, 1),
      3px 3px 0 hsla(0, 100%, 50%, 1), 2px 2px 0 hsla(0, 100%, 50%, 1),
      1px 1px 0 hsla(0, 100%, 50%, 1);
  }
  50% {
    text-shadow: -4px 4px 0 hsla(0, 100%, 50%, 1),
      -3px 3px 0 hsla(0, 100%, 50%, 1), -2px 2px 0 hsla(0, 100%, 50%, 1),
      -1px 1px 0 hsla(0, 100%, 50%, 1), 4px -4px 0 hsla(180, 100%, 50%, 1),
      3px -3px 0 hsla(180, 100%, 50%, 1), 2px -2px 0 hsla(180, 100%, 50%, 1),
      1px -1px 0 hsla(180, 100%, 50%, 1);
  }
  75% {
    text-shadow: 4px 4px 0 hsla(180, 100%, 50%, 1),
      3px 3px 0 hsla(180, 100%, 50%, 1), 2px 2px 0 hsla(180, 100%, 50%, 1),
      1px 1px 0 hsla(180, 100%, 50%, 1), -4px -4px 0 hsla(0, 100%, 50%, 1),
      -3px -3px 0 hsla(0, 100%, 50%, 1), -2px -2px 0 hsla(0, 100%, 50%, 1),
      -1px -1px 0 hsla(0, 100%, 50%, 1);
  }
  100% {
    text-shadow: 4px -4px 0 hsla(0, 100%, 50%, 1),
      3px -3px 0 hsla(0, 100%, 50%, 1), 2px -2px 0 hsla(0, 100%, 50%, 1),
      1px -1px 0 hsla(0, 100%, 50%, 1), -4px 4px 0 hsla(180, 100%, 50%, 1),
      -3px 3px 0 hsla(180, 100%, 50%, 1), -2px 2px 0 hsla(180, 100%, 50%, 1),
      -1px 1px 0 hsla(180, 100%, 50%, 1);
  }
}

/* MODEL CONTROL BUTTON */
