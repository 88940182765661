/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

/*index.css*/
* {
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  background: #fff;
  font-family: -apple-system, BlinkMacSystemFont, avenir next, avenir,
    helvetica neue, helvetica, ubuntu, roboto, noto, segoe ui, arial, sans-serif;
}

@font-face {
  font-family: "AppleColorEmoji";
  src: url("./fonts/AppleColorEmoji.ttc");
}

@font-face {
  font-family: "EmojiOneColor";
  src: url("./fonts/EmojiOneColor.otf") format("truetype");
}

@font-face {
  font-family: "GabrielaStencil";
  src: url("./fonts/gabrielastencil-black.ttf") format("truetype");
  font-weight: bold;
}
